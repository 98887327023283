<!--  -->
<template>
  <div class="form-wrapper">
    <van-form ref="form" v-if="showForm">
      <van-field
        readonly
        disabled
        v-model="form.applyName"
        name="applyName"
        label="用户名"
        placeholder="请输入姓名"
        required
      />
      <van-field-select
        v-model="form.idCardType"
        :options="idCardTypeOptions"
        :fieldProps="{
          label: '证件类型',
          required: true,
          readonly: true,
          disabled: true,
          placeholder: '请选择证件类型',
        }"
      ></van-field-select>
      <van-field
        readonly
        disabled
        v-model="form.idCardNum"
        name="idCardNum"
        label="证件号码"
        placeholder="请输入证件号码"
        required
      />
      <van-field
        v-model="form.phone"
        name="phone"
        label="手机号"
        placeholder="请输入手机号"
        required
      />
      <van-field
        v-model="form.bankId"
        name="bankId"
        label="社会保障卡开户银行"
        placeholder="请输入社会保障卡开户银行"
        required
      />
      <van-field
        v-model="form.bankAccount"
        name="bankAccount"
        label="社会保障卡金融账号"
        placeholder="请输入社会保障卡金融账号"
        required
      />
      <van-field
        v-model="form.eduTalentType"
        name="eduTalentType"
        label="教育人才类别"
        placeholder="请输入教育人才类别"
        required
        readonly
        disabled
      />
      <van-field-select
        v-model="form.platSupportType"
        :options="platSupportTypeOptions"
        :fieldProps="{
          label: '平台资助类别',
          required: true,
          placeholder: '请选择证件类型',
        }"
      ></van-field-select>
      <van-field
        v-model="form.workCmpy"
        name="workCmpy"
        label="工作单位"
        placeholder="请输入工作单位"
        required
      />
    </van-form>
    <div v-else>
      <van-empty description="暂无教育人才认证,无法办理" />
    </div>
  </div>
</template>

<script>
import formMixin from "../mixin";
import Schema from "async-validator";
import rules from "./rules";
export default {
  data() {
    return {
      showForm: false,
      form: {
        applyName: "",
        idCardType: "居民身份证",
        phone: "",
        idCardNum: "",
        bankAccount: "",
        bankId: "",
        workCmpy: "",
        eduTalentType: "",
        platSupportType: "",
      },
      rules: rules,
      platSupportTypeOptions: [
        {
          label: "名师工作室",
          value: "名师工作室",
        },
        {
          label: "名校长工作室",
          value: "名校长工作室",
        },
      ],
    };
  },
  mixins: [formMixin],
  props: {
    detail: Object,
  },
  watch: {
    userInfo() {
      if (this.userInfo) {
        this.authCheck();
      }
    },
  },
  created() {
    if (this.userInfo) {
      this.authCheck();
    }
  },
  methods: {
    authCheck() {
      const talentType = "2"; //教育人才
      this.$store
        .dispatch("policy/getUserTalentTypesByIdcard", {
          idCard: this.userInfo.idCard,
          userName: this.userInfo.rnName,
          type: talentType,
        })
        .then((res) => {
          if (res) {
            let strs = [];
            strs = res.map((item) => {
              return item.typeName;
            });
            let libraryStr = strs.join("/");
            this.form.eduTalentType = libraryStr;
            this.showForm = true;
          } else {
            this.authCheckFail();
          }
        })
        .catch((err) => {
          this.authCheckFail();
        });
    },
    authCheckFail() {
      this.$toast("暂无教育人才认证,无法办理");
      this.$emit("authCheck", false);
      this.showForm = false;
    },
    validate() {
      const validator = new Schema(this.rules);
      return new Promise((resolve, reject) => {
        validator
          .validate(this.form, (errors) => {
            if (errors && errors.length) {
              this.$toast(errors[0].message);
            }
          })
          .then(() => {
            resolve(this.form);
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.form-wrapper {
  margin-top: 0.2rem;
  background: #fff;
}
.tip {
  margin: 0.2rem;
}
</style>
